import { Typography } from "@mui/material";

export const KeyLabel = ({ text }) => {
  return (
    <Typography
      component="span"
      sx={{
        display: "inline-block",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        padding: "2px 4px",
        borderRadius: "4px",
        fontSize: "0.8rem",
        lineHeight: "1rem",
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.6)",
        mb: -1,
      }}
    >{text}
    </Typography>
  )
}
